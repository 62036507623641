import React, {  useEffect, useMemo } from 'react'
import Cookies from 'universal-cookie'
import { Link, redirect } from 'react-router-dom'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SetCSRF }  from '../../components/functions/SetCSRF'
import { useAuth } from '../../contexts/AuthContext'
import { Banner } from '../banner/Banner'
import { AuthInputField } from '../functions/AuthInputField'

const Login = () => {
    const { login, authError, setAuthError } = useAuth()

    const validationSchema = Yup.object().shape({
        email: Yup.string().nullable()
            .required('Email required'),
        password: Yup.string().nullable()
            .required('Password required')
    })

    const urlParams = new URLSearchParams(window.location.search);
    const next = urlParams.get('next');
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, getValues, setError, handleSubmit, setValue, formState } = useForm(formOptions);
    const { errors } = formState;

    const cookies = useMemo(() => new Cookies(), [])


    useEffect(() => {
        if (!cookies.get('csrftoken')) {
            SetCSRF()
        }
        setAuthError({})
    }, [cookies, setAuthError])

    async function onSubmit(data) {
        await login(data.email, data.password)
        redirect('/dashboard')
    }

    return (
        <>
        {Object.keys(authError).length > 0 ?
            Object.keys(authError).map((key, index) => {
                if (Array.isArray(authError[key])) {
                    return(authError[key].map((err) => (<Banner key={index} type='error' mainText={err} />)))
                } else if (typeof authError[key] === 'string') {
                    return(<Banner key={index} type='error' mainText={authError[key]} />)
                } else {
                    return(<Banner key={index} type='error' mainText={`An error occured, please try again. If this persists, please contact support at ${process.env.REACT_APP_TENANT_CONTACT_EMAIL}`} />)
                }
            })
        :
        <></>
        }
        <div className="flex flex-col">
            <div>
                <h2 className="text-lg font-semibold text-gray-900 leading-8">
                    Sign in to your account
                </h2>
                <p className="mt-2 text-sm text-gray-700 leading-6">
                    Don't have an account? <Link className="font-medium text-blue-600 hover:underline inline cursor-pointer" to='/register'>Sign up</Link> now
                </p>
            </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-5 grid grid-cols-1 gap-y-8">
            <input type="hidden" name="next" value={ next } />
            <AuthInputField
                id='email'
                name='Email address'
                placeholder='Email'
                formRegister={register('email')}
                formRegisterName='email'
                setValue={setValue}
                errors={errors.email}
                setError={setError}
                getValues={getValues}
                type='email'
            />
            <AuthInputField
                id='password'
                name='Password'
                placeholder='Password'
                formRegister={register('password')}
                formRegisterName='password'
                setValue={setValue}
                errors={errors.password}
                setError={setError}
                getValues={getValues}
                type='password'
            />
            <div className="text-center">
              <button
                type="submit"
                className="inline-flex justify-center rounded-full w-full border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span>Sign in <span aria-hidden="true">&rarr;</span></span>
              </button>
              <hr className='my-4 mt-5' />
              <a className='text-sm text-blue-800' href='/password-reset'>Forgot password?</a>
            </div>
        </form>
        </>
    )
}

export default Login
