import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { Tab, RadioGroup } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { AutocorrectDropdown } from '../../components/functions/AutocorrectDropdown'
import { InputField } from '../../components/functions/InputField'
import { SetCSRF }  from '../../components/functions/SetCSRF'
import { Banner } from '../../components/banner/Banner'
import { GetCountries } from '../../components/functions/GetCountries'

const cargoSizes = ['20ft', '40ft', '40ft HC']


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const NewQuote = () => {
  const [companies, setCompanies] = useState([])
  const [ports, setPorts] = useState([])
  const [countries, setCountries] = useState([])
  const [originSelected, setOrigin] = useState(0)
  const [destinationSelected, setDestination] = useState(0)
  const [cargoType, setCargo] = useState(false)
  const [apiResponse, setAPIResponse] = useState()
  const [apiError, setAPIError] = useState()

  
  const validationSchema = Yup.object().shape({
    company: Yup.object()
        .required('Company required'),
    origin_type: Yup.string().nullable()
        .required('Origin required'),
    origin_port: Yup.object()
        .when('origin_type', {
          is: 'port',
          then: Yup.object().nullable()
            .required('Port required'),
        }),
    origin_address_line1: Yup.string()
        .when('origin_type', {
          is: 'address',
          then: Yup.string()
            .required('Address required'),
        }),
    origin_address_city: Yup.string()
        .when('origin_type', {
          is: 'address',
          then: Yup.string()
            .required('City required'),
        }),
    origin_address_state: Yup.string()
        .when('origin_type', {
          is: 'address',
          then: Yup.string()
            .required('State required'),
        }),
    origin_address_postcode: Yup.string()
        .when('origin_type', {
          is: 'address',
          then: Yup.string()
            .required('Zip / Postal Code required'),
        }),
    origin_address_country: Yup.object()
        .when('origin_type', {
          is: 'address',
          then: Yup.object().nullable()
            .required('Country required'),
        }),
    destination_type: Yup.string().nullable()
        .required('Destination required'),
    destination_port: Yup.object()
        .when('destination_type', {
          is: 'port',
          then: Yup.object().nullable()
            .required('Port required'),
        }),
    destination_address_line1: Yup.string()
        .when('destination_type', {
          is: 'address',
          then: Yup.string()
            .required('Address required'),
        }),
    destination_address_city: Yup.string()
        .when('destination_type', {
          is: 'address',
          then: Yup.string()
            .required('City required'),
        }),
    destination_address_state: Yup.string()
        .when('destination_type', {
          is: 'address',
          then: Yup.string()
            .required('State required'),
        }),
    destination_address_postcode: Yup.string()
        .when('destination_type', {
          is: 'address',
          then: Yup.string()
            .required('Zip / Postal Code required'),
        }),
    destination_address_country: Yup.object()
        .when('destination_type', {
          is: 'address',
          then: Yup.object().nullable()
            .required('Country required'),
        }),
    type: Yup.string()
        .required('Cargo required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, setError ,getValues, handleSubmit, setValue, formState } = useForm(formOptions);
  const { errors } = formState;

  function updateCargo(i) {
    setCargo(i)
    setValue('type', i)
    errors.type = null
  }

  function setOriginType(i) {
    setOrigin(i)
    if (i === 0) {
      setValue('origin_type', 'port')
    } else {
      errors.origin_port = null
      setValue('origin_type', 'address')
    }
  }

  function setDestinationType(i) {
    setDestination(i)
    if (i === 0) {
      setValue('destination_type', 'port')
    } else {
      errors.destination_port = null
      setValue('destination_type', 'address')
    }
  }

  const apiURL = process.env.REACT_APP_API_URL
  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    if (!cookies.get('csrftoken')) {
      SetCSRF()
    }
    const getCompanies = () => {
      axios.get(`${apiURL}/api/v1/quotes/admin/companies/`)
        .then((response) => {
          const allCompanies = response.data
          setCompanies(allCompanies)
        })
        .catch(error => console.error(`Error: ${error}`))
        // HANDLE ERROR
    }
    getCompanies()
    const getPorts = () => {
      axios.get(`${apiURL}/api/v1/quotes/admin/ports/`)
        .then((response) => {
          const allPorts = response.data
          setPorts(allPorts)
        })
        .catch(error => console.error(`Error: ${error}`))
        // HANDLE ERROR
    }
    getPorts()
    GetCountries().then(data => setCountries(data)).catch((error) => console.log(error))
    setValue('origin_type', 'port')
    setOrigin(0)
    setValue('destination_type', 'port')
    setDestination(0)
  }, [cookies, setValue, apiURL])

  function onSubmit(data) {
    const alteredData = data
    alteredData['company'] = data['company']['id']
    if (alteredData['origin_type'] === 'port') {
      alteredData['origin_port'] = data['origin_port']['id']
    } else {
      alteredData['origin_address_country'] = data['origin_address_country']['id']
    }
    if (alteredData['destination_type'] === 'port') {
      alteredData['destination_port'] = data['destination_port']['id']
    } else {   
      alteredData['destination_address_country'] = data['destination_address_country']['id']
    }
    axios.post(
      `${apiURL}/api/v1/quotes/admin/new/`,
      alteredData,
      {
        "Content-Type": "application/json",
        "csrftoken": cookies.get('csrftoken'),
      }
    ).then((res) => {
      setAPIResponse(res.data)
    }).catch(function (error) {
      if (error.response.data.detail) {
        setAPIError({...apiError, 'mainMessage': error.response.data.detail})
      } else {
        setAPIError(error.response.data)
      }
    })
  }
    
  return (
    <>
      {apiError ?
      <Banner
        type='error'
        mainText={apiError.mainMessage}
      />
      :
      <div></div>
      }
      {apiResponse ?
      <Banner
        type={apiResponse.type}
        mainText={apiResponse.message}
        smallDeviceText={apiResponse.mobileMessage}
        to={apiResponse.to}
        callToAction={apiResponse.callToAction}
      />
      :
      <div></div>
      }
      <div className="flex flex-1 flex-col">
        <form onSubmit={handleSubmit(onSubmit)} className="xl:flex xl:justify-center">
          <div className="shadow sm:overflow-hidden sm:rounded-md xl:min-w-[64rem] max-w-5xl">
            <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">New Quote</h3>
                <p className="mt-1 text-sm text-gray-500">This page is only available to {process.env.REACT_APP_TENANT_COMPANY_NAME} staff.</p>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <AutocorrectDropdown 
                    name="company" 
                    placeholder='Search companies' 
                    dropdownItems={companies}
                    formRegister={register('company')}
                    formRegisterName='company'
                    setValue={setValue}
                    errors={errors.company}
                    setError={setError}
                    getValues={getValues}
                    colName='name'
                />
                <div className={`col-start-1 col-span-6 lg:col-span-3 lg:col-start-1 rounded-xl bg-gray-100 p-3 ${ originSelected === 0 ? "max-h-[10.1rem]" : "" } ${ errors.origin_port && originSelected === 0 ? "!max-h-[11.8rem]" : "" }`}>
                <label className="block text-sm font-medium text-gray-700 col-span-3 mb-1 -mt-1">
                  Origin
                </label>
                <div className='col-start-1 col-span-3'><div className='grid grid-cols-3'>
                <Tab.Group selectedIndex={originSelected} onChange={setOriginType}>
                  <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 col-span-6 sm:col-span-6 lg:col-span-2">
                    <Tab className={({ selected }) =>
                      classNames(
                        'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                        'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        selected
                          ? 'bg-white shadow'
                          : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                      )
                    }>
                      Port
                    </Tab>
                    <Tab className={({ selected }) =>
                      classNames(
                        'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                        'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                        selected
                          ? 'bg-white shadow'
                          : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                      )
                    }>
                      Address
                    </Tab>
                  </Tab.List>
                  <Tab.Panels className="col-start-1 col-span-6 lg:col-span-3">
                    <Tab.Panel>
                      <div className='grid grid-cols-4 gap-2'>
                        <div className='col-span-4 mt-2'>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Origin port
                          </label>
                          <AutocorrectDropdown 
                            name="origin_port" 
                            placeholder='Search ports' 
                            dropdownItems={ports}
                            formRegister={register('origin_port')}
                            formRegisterName='origin_port'
                            setValue={setValue}
                            errors={errors.origin_port}
                            setError={setError}
                            getValues={getValues}
                            colName='port_name'
                          />
                        </div>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel>
                      <div className='grid grid-cols-4 gap-2'>
                        <div className="col-span-4 mt-2">
                          <InputField
                            id='street-address'
                            name='Street address'
                            placeholder='Enter street address'
                            formRegister={register('origin_address_line1')}
                            formRegisterName='origin_address_line1'
                            setValue={setValue}
                            errors={errors.origin_address_line1}
                            setError={setError}
                            getValues={getValues}
                          />
                        </div>
                        <div className="col-span-4">
                          <InputField
                            id='city'
                            name='City'
                            placeholder='Enter city'
                            formRegister={register('origin_address_city')}
                            formRegisterName='origin_address_city'
                            setValue={setValue}
                            errors={errors.origin_address_city}
                            setError={setError}
                            getValues={getValues}
                          />
                        </div>
                        <div className="col-span-4">
                          <InputField
                            id='region'
                            name='State / Province'
                            placeholder='Enter state/province'
                            formRegister={register('origin_address_state')}
                            formRegisterName='origin_address_state'
                            setValue={setValue}
                            errors={errors.origin_address_state}
                            setError={setError}
                            getValues={getValues}
                          />
                        </div>
                        <div className='col-span-4 xl:col-span-2'>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Country
                          </label>
                          <AutocorrectDropdown 
                            name="origin_address_country" 
                            placeholder='Search countries' 
                            dropdownItems={countries}
                            formRegister={register('origin_address_country')}
                            formRegisterName='origin_address_country'
                            setValue={setValue}
                            errors={errors.origin_address_country}
                            setError={setError}
                            getValues={getValues}
                            colName='country'
                          />
                        </div>
                        <div className='col-span-4 xl:col-span-2'>
                          <InputField
                            id='postal-code'
                            name='ZIP / Postal code'
                            placeholder='Enter ZIP/postcode'
                            formRegister={register('origin_address_postcode')}
                            formRegisterName='origin_address_postcode'
                            setValue={setValue}
                            errors={errors.origin_address_postcode}
                            setError={setError}
                            getValues={getValues}
                          />
                        </div>
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
                </div></div></div>
                <div className={`col-start-1 col-span-6 lg:col-span-3 lg:col-start-4 rounded-xl bg-gray-100 p-3 ${ destinationSelected === 0 ? "max-h-[10.1rem]" : ""} ${ errors.destination_port && destinationSelected === 0 ? "!max-h-[11.8rem]" : "" }`}>
                <label className="block text-sm font-medium text-gray-700 mb-1 -mt-1">
                  Destination
                </label>
                <div className='col-start-1 col-span-3 lg:col-start-4'><div className='grid grid-cols-3'>
                <Tab.Group selectedIndex={destinationSelected} onChange={setDestinationType}>
                <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 col-start-1 col-span-6 sm:col-span-6 lg:col-span-2">
                    <Tab className={({ selected }) =>
                      classNames(
                        'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-indigo-600',
                        'ring-white ring-opacity-60 ring-offset-2 ring-offset-indigo-600 focus:outline-none focus:ring-2',
                        selected
                          ? 'bg-white shadow'
                          : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                      )
                    }>
                      Port
                    </Tab>
                    <Tab className={({ selected }) =>
                      classNames(
                        'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-indigo-600',
                        'ring-white ring-opacity-60 ring-offset-2 ring-offset-indigo-600 focus:outline-none focus:ring-2',
                        selected
                          ? 'bg-white shadow'
                          : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                      )
                    }>
                      Address
                    </Tab>
                  </Tab.List>
                  <Tab.Panels className="col-start-1 col-span-6 lg:col-span-3">
                    <Tab.Panel>
                      <div className='grid grid-cols-4 gap-2'>
                        <div className='col-span-4 mt-2'>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Destination port
                          </label>
                          <AutocorrectDropdown 
                            name="destination_port" 
                            placeholder='Search ports' 
                            dropdownItems={ports}
                            formRegister={register('destination_port')}
                            formRegisterName='destination_port'
                            setValue={setValue}
                            errors={errors.destination_port}
                            setError={setError}
                            getValues={getValues}
                            colName='port_name'
                          />
                        </div>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel>
                      <div className='grid grid-cols-4 gap-2'>
                        <div className="col-span-4 mt-2">
                          <InputField
                            id='street-address'
                            name='Street address'
                            placeholder='Enter street address'
                            formRegister={register('destination_address_line1')}
                            formRegisterName='destination_address_line1'
                            setValue={setValue}
                            errors={errors.destination_address_line1}
                            setError={setError}
                            getValues={getValues}
                          />
                        </div>
                        <div className="col-span-4">
                          <InputField
                            id='city'
                            name='City'
                            placeholder='Enter city'
                            formRegister={register('destination_address_city')}
                            formRegisterName='destination_address_city'
                            setValue={setValue}
                            errors={errors.destination_address_city}
                            setError={setError}
                            getValues={getValues}
                          />
                        </div>
                        <div className="col-span-4">
                          <InputField
                            id='region'
                            name='State / Province'
                            placeholder='Enter state/province'
                            formRegister={register('destination_address_state')}
                            formRegisterName='destination_address_state'
                            setValue={setValue}
                            errors={errors.destination_address_state}
                            setError={setError}
                            getValues={getValues}
                          />
                        </div>
                        <div className='col-span-4 xl:col-span-2'>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Country
                          </label>
                          <AutocorrectDropdown 
                            name="destination_address_country" 
                            placeholder='Search countries' 
                            dropdownItems={countries}
                            formRegister={register('destination_address_country')}
                            formRegisterName='destination_address_country'
                            setValue={setValue}
                            errors={errors.destination_address_country}
                            setError={setError}
                            getValues={getValues}
                            colName='port_name'
                          />
                        </div>
                        <div className='col-span-4 xl:col-span-2'>
                          <InputField
                            id='postal-code'
                            name='ZIP / Postal code'
                            placeholder='Enter ZIP/postcode'
                            formRegister={register('destination_address_postcode')}
                            formRegisterName='destination_address_postcode'
                            setValue={setValue}
                            errors={errors.destination_address_postcode}
                            setError={setError}
                            getValues={getValues}
                          />
                        </div>
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
                </div></div></div>
              </div>
              <RadioGroup {...register("type")} defaultValue={null} value={cargoType} onChange={updateCargo} name="cargoSize">
                <RadioGroup.Label className="block text-sm font-medium text-gray-700">Container Type</RadioGroup.Label>
                <div className="grid grid-cols-3 gap-3 lg:grid-cols-6">
                  {cargoSizes.map((type) => (
                    <RadioGroup.Option
                      key={type}
                      value={type}
                      className={({ active, checked }) =>
                        classNames(
                          checked
                            ? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700'
                            : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                          'cursor-pointer focus:outline-none border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1'
                        )
                      }
                    >
                      <RadioGroup.Label as="span">{type}</RadioGroup.Label>
                    </RadioGroup.Option>
                  ))}
                </div>
                {errors.type?.message &&
                  <div className="mt-2 text-sm text-red-600 inline-block">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500 inline-block" />
                    <p className="inline-block ml-2">{errors.type.message}</p>
                  </div>
                }
              </RadioGroup>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default NewQuote
